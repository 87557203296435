import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useOrganizationByDomainQuery } from '../../generated/types-and-hooks';
import { Loading } from '../modules/common/components/Loading';
import { Error404View } from '../modules/errors/views/Error404View';

const knownApex = ['localhost', 'frond.dev', 'frond.com'];

/**
 * Entry point for the root of a custom domain, rewritten in middleware.
 * This lets us get the user to the right spot without breaking SSR on the homepage
 */
const DomainResolverPage: NextPage = () => {
  const router = useRouter();
  const [hostname, setHostname] = useState<string | null>(null);
  const isCustomDomain = hostname && !knownApex.includes(hostname);

  const { data, loading } = useOrganizationByDomainQuery({
    variables: { domain: hostname || '' },
    skip: !isCustomDomain,
  });

  useEffect(() => {
    setHostname(window.location.hostname);
  }, []);

  useEffect(() => {
    const shortId = data?.organizationByDomain?.shortId;
    if (shortId) {
      router.push(`/${shortId}`);
    }
  }, [router, data?.organizationByDomain?.shortId]);

  if (hostname && !data?.organizationByDomain && !loading) {
    return <Error404View />;
  }

  return <Loading />;
};

export default DomainResolverPage;
